<template>
  <v-container class="profile-section">
    <div @click="clicked">
      <Breadcrumb :items="breadCrumbs" />
    </div>
    <router-view></router-view>
  </v-container>
</template>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "Profile",
  components: { Breadcrumb },
  mixins: [clickHandler],
  computed: {
    breadCrumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      const breadCrumbs = [{ href: "/", text: "Homepage" }];
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = "";
      // let lastIndexFound = 0;
      for (let i = 0; i < pathArray.length; ++i) {
        breadcrumb = `${breadcrumb}${"/"}${pathArray[i]}`;
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
        ) {
          breadCrumbs.push({
            href: breadcrumb,
            // i !== 0 && pathArray[i - (i - lastIndexFound)]
            //   ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
            //   : breadcrumb,
            disabled: i + 1 === pathArray.length,
            text: this.$route.matched[i].meta.breadCrumb || pathArray[i]
          });
          // lastIndexFound = i;
          // breadcrumb = "";
        }
      }
      return breadCrumbs;
    }
  }
};
</script>
